@import "~@/erp/styles/variables/variables.scss";


















.no-mail {
  width: 100%;
  height: 100%;
  background: #fff;
  border-left: 1px solid #ebeef5;
  box-sizing: border-box;
  position: relative;
  & > div {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100px;
      height: 100px;
    }
    p {
      margin-top: 33px;
      color: #777;
      font-size: 14px;
    }
  }
}
