@import "~@/erp/styles/variables/variables.scss";




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mail-detail {
  background: #fff;
  border-left: 1px solid #ebeef5;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .mail-detail-base {
    border-bottom: 1px solid #ebeef5;
    padding: 20px;
    box-sizing: border-box;

    .title-box {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      margin-bottom: 10px;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #303030;
        max-width: 150em;
        word-break: break-all;
      }

      .order-num {
        padding: 0 3px;
        white-space: nowrap;
        color: #1890ff;
        background: #e8f4ff;
        font-size: 14px;
        margin-left: 10px;

        .icon-close {
          color: #ccc;
        }
      }

      .label-item {
        display: inline-block;
        margin-left: 10px;
        height: 20px;
        font-size: 12px;
        color: #fff;
        padding: 0 4px;
        border-radius: 2px;
        max-width: 100%;
        line-height: 20px;

        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          vertical-align: middle;
        }

        .remove-icon {
          margin-left: 2px;
          cursor: pointer;
          opacity: 0;
          vertical-align: middle;
        }

        &:hover {
          .remove-icon {
            opacity: 1;
          }
        }
      }
    }

    .operate-btns {
      margin-left: 10px;
      width: 80px;
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
      height: 28px;

      .el-button {
        width: 28px;
        height: 28px;
        padding: 4px;
        font-size: 18px;
      }
    }

    .info-wrapper {
      display: flex;

      .info-main {
        flex: 1;
        width: 200px;
      }

      .info-simple {
        font-size: 14px;
        color: #333;
        line-height: 24px;
        word-break: break-all;

        .desc {
          color: #999;
        }
      }

      .btn-key {
        font-size: 14px;
        color: #999;
        line-height: 24px;
        cursor: pointer;

        &:hover {
          color: #1890ff;
        }
      }
    }
    .sage-order {
      display: flex;
      align-items: center;
      color: #F59A23;
      > span {
        color: #1990FF;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .base-item {
      display: flex;
      margin-bottom: 10px;
      line-height: 24px;

      dt {
        width: 82px;
        height: 24px;
        background: #f8fbff;
        border-radius: 3px;
        text-align: center;
        line-height: 24px;
        margin-right: 10px;
      }

      dd {
        flex: 1;
        width: 0;
        word-wrap: break-word;
        font-size: 14px;
        color: #777;

        .weight {
          color: #303030;
        }

        .star {
          color: #fa6400;
        }
      }
    }

    .myremark {
      background-color: #e8f4ff;
      color: #1890ff;
      border-radius: 5px;
      padding: 4px 6px;
      margin-top: 6px;

      .remark-text {
        display: flex;
        align-items: flex-end;

        .weight {
          font-size: 14px;
          line-height: 28px;
          word-wrap: break-word;
          flex: 1;
          width: 200px;
        }
      }

      .remark-form {
        display: flex;
        align-items: center;

        .input {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
  }

  .mail-detail-html {
    padding: 40px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef5;
    flex: 1;

    overflow-x: hidden;
    overflow-y: auto;

    ::v-deep p {
      word-break: break-all;
    }

    ::v-deep img {
      max-width: 100%;
    }

    ::v-deep hr {
      display: block;
      margin: 10px 0;
      border: 0;
      width: 200px;
      border-top: 1px solid #ccc;
    }
  }

  .mail-detail-attach {
    padding: 20px 20px 0;
    box-sizing: border-box;

    &>div:nth-child(1) {
      i {
        color: #fa6400;
        font-size: 14px;
      }

      &>span {
        font-size: 14px;
        font-weight: bold;
        color: #303030;
        margin: 0 10px;
      }
    }

    &>div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;

      &>div {
        padding: 0 12px;
        background: #f5f7fa;
        border-radius: 3px;
        border: 1px solid #ebeef5;
        font-size: 12px;
        margin-right: 10px;
        line-height: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 200px;
        display: flex;

        i {
          color: #fa6400;
          font-size: 12px;
        }

        .title {
          flex: 1;
          width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #303030;
          line-height: 30px;
          margin-left: 10px;
        }

        .preview {
          color: #1890ff;
          margin-left: 10px;
        }

        .size {
          color: #777;
          margin-left: 10px;
        }
      }
    }
  }

  .mail-detail-btns {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }

  .mail-iframe-wrapper {
    flex: 1;
    height: 500px;
    position: relative;

    td {
      border: 1px solid #ddd;
    }

    .mail-iframe {
      height: 100%;
      width: 100%;
    }

    .mail-iframe-div {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1111;
      filter: alpha(opacity=0);
      opacity: 0;
      background: transparent;
      display: none;
    }
  }

  .mail-deatil-bottom {
    max-height: 230px;
  }
}

.gmail_attr {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 14px;
}
