@import "~@/erp/styles/variables/variables.scss";


























































































































































































































.wrapper {
  display: flex;
  flex-direction: column;


  .tabs-cont {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: space-around;

    li {
      height: 100%;
      padding: 0 5px;
      list-style: none;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;


      div {
        cursor: pointer;
        position: relative;
        color: #666;
        font-size: 14px;

        &.active {
          font-weight: 700;
          color: #333;
        }

        .num {
          position: absolute;
          top: -10px;
          left: 100%;
          color: rgba(217, 0, 27, 1);
          font-weight: 700;
        }
      }
    }
  }


  .tab-search {
    i {
      margin-right: 10px;
    }

    padding: 0 10px;
    display: flex;
    align-items: center;

    .el-input-group__prepend {
      background: #fff !important;
    }
  }
}

.el-icon-date {
  cursor: pointer;

  &.active-date {
    color: #409EFF;
  }
}

.el-date-editor {
  flex: 1;
}

.date-search-btn {
  background-color: #F5F7FA;
  height: 28px;
  width: 47px;
  display: flex;
  align-items: center;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  i {
    margin-right: 0px;
  }

}
