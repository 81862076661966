@import "~@/erp/styles/variables/variables.scss";































































































































































































































































































































































































































































































































































































































































































































































































































































.inquiry-content {
  padding: 0 10px 20px;
  .inquiry-title {
    line-height: 32px;
    display: flex;
    height: 32px;
    margin: 20px 0 10px 0;
    h4 {
      line-height: 32px;
      font-family: 'PingFang Bold';
      font-size: 14px;
      margin: 0 10px 0 0;
    }
  }
  .inquiry-mail {
    margin-top: 20px;
    & > div:first-child {
      margin-bottom: 10px;
    }
    & > div:last-child {
      padding: 20px 0;
      background: #f5f7fa;
      box-sizing: border-box;
    }
  }
  h4 {
    font-family: 'PingFang Bold';
    font-size: 14px;
    margin: 20px 0 10px 0;
    &.mt0 {
      margin: 0 0 10px 0;
    }
  }
  .el-input,
  .el-select {
    width: 100%;
  }
  .empty {
    width: 100%;
    border: 1px solid #ebeef5;
    border-top: none;
    text-align: center;
    line-height: 50px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
.offer-container {
  height: 600px;
  overflow-y: auto;
}
.oper-btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 20px 0;
  background: white;
}

.inquiry-footer {
  text-align: center;
}
