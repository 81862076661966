@import "~@/erp/styles/variables/variables.scss";























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.offer-container {
  h4 {
    margin-top: 0px;
  }

  .product-container {
    max-height: 420px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;

    .add {
      text-align: center;
      line-height: 30px;
      margin-bottom: 10px;

      span {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }

  .net-price {
    position: relative;

    .el-button {
      position: absolute;
      top: 11px;
      right: -5px;
      z-index: 2;
      height: 28px;
      width: 28px;
      margin: 0;
    }
  }
}

::v-deep {
  .net-price .el-icon-edit {
    margin-top: -2px;
    text-indent: -2px;
  }

  .el-upload--picture-card {
    width: 88px;
    height: 88px;

    i {
      line-height: 88px;
      display: block;
    }
  }

  .el-upload-list__item {
    width: 88px;
    height: 88px;
  }

  .input-style {
    width: 120px;
  }
}

.pdf-wrapper {
  position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate3d(-50%, -50%, 0);
  left: 110vw;
  top: 110vh;
  width: 1400px;
  height: 100vh;
  z-index: 9999;
  background-color: #fff;
}
