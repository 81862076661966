@import "~@/erp/styles/variables/variables.scss";



































































































































































































































































































































































































.search-form {
  height: 90%;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 40px;
  .search-form-content {
    padding: 0 20px;
    .el-form-item {
      span {
        color: #000;
      }
      .el-select {
        width: 100%;
      }
    }
  }
  .operate {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ededed;
    padding: 20px;
  }
}
